<template>
  <!--begin::Card-->
  <div>
    <form
      class="form"
      novalidate="novalidate"
      id="pg_form"
    >
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.name"
          name="name"
          ref="name"
          placeholder="Group Name"
        />
        <span class="form-text text-muted"
          >Enter a name for this group of dish properties.</span
        >
      </div>
      <div class="form-group">
        <label>Printing Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.printing_name"
          name="printing_name"
          ref="printing_name"
          placeholder="Group Printing Name"
        />
        <span class="form-text text-muted">
          Enter a printing name for this group of dish properties.
        </span>
      </div>
      <div class="form-group">
        <label>Price</label>
        <b-form-radio-group
          v-model="form.price"
          :options="price_options"
          name="price"
          ref="price"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Enter the price for this option.
        </span>
      </div>
      <div class="form-group">
        <label>Required</label>
        <b-form-radio-group
          v-model="form.required"
          :options="options"
          name="required"
          ref="required"
        ></b-form-radio-group>
        <span class="form-text text-muted">
          Check if this item is compulsory.
        </span>
      </div>
      <div class="form-group">
        <label>Required Count</label>
        <input
          type="number"
          class="form-control form-control-solid form-control-lg"
          v-model="form.required_count"
          placeholder="Property Group Required Count"
        />
      </div>
      <div class="row justify-content-end">
        <button
          ref="pg_form_submit"
          class="btn btn-primary mt-3 mr-4"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "PropertyGroupForm",
  props: ['id'],
  data() {
    return {
      form: {
        id: '',
        name: '',
        printing_name: '',
        required: false,
        required_count: 0,
        price: 'price'
      },
      price_options: [
        { text: "Price", value: "price" },
      ],
      options: [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" }
      ],
    };
  },
  mounted() {
    const pg_form = KTUtil.getById("pg_form");
    this.fv = formValidation(pg_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        },
        printing_name: {
          validators: {
            notEmpty: {
              message: "Description is required"
            }
          }
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      const form = this.form;
      // set spinner to submit button
      const submitButton = this.$refs["pg_form_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var url = 'business/menu/property-groups';

      if(this.id){
        url = 'business/menu/property-groups/'+this.id;
        form._method = 'PUT';
      }

      ApiService.post(url, form)
      .then(({ data }) => {
        if (data.data)
          {
            if (data.data.id > 0)
            {
              Swal.fire("Information!", "Property Group saved successfully !", "success");
              this.$emit("success");
            }
          }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "",
          text: "Form Error",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });

      //Remove Spineer
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    if(this.id)
      this.get(this.id);
  },
  methods: {
    get(id) {
      ApiService.get('business/menu/property-groups', id)
      .then(({ data }) => {
        let pg = data.data;
        this.form.id = pg.id;
        this.form.name = pg.name;
        this.form.printing_name = pg.printing_name;
        this.form.price = pg.price;
        this.form.required = (pg.required == true) ? 1 : 0;
        this.form.required_count = pg.required_count;
      });
    },
  }
};
</script>
